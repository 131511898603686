import api from "@/lib/api.service";

const URL = "/form";

export const getAllForms = async () => {
  const { data } = await api.get(`${URL}/list`);
  console.log(data);
  return data;
};
export const formDeleteRequest = async (id) => {
  const { data } = await api.post(`${URL}/delete`, { _id: id });
  return data;
};

// export const getbyFormIdCompletedForms = async (formId) => {
//   const { data } = await api.get(`${URL}/get?formID=${formId}`);
//   return data;
// };

// export const createCompletedForms = async (form) => {
//   const { data } = await api.post(URL, form);
//   return data;
// };

// export const updateCompletedForms = async (id, form) => {
//   const { data } = await api.put(`${URL}/update?formId=${id}`, form);
//   return data;
// };

// export const deleteCompletedForms = async (id) => {
//   return api.delete(`${URL}/${id}`);
// };
