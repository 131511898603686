import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { MoreHorizontal, Printer, View } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

const DataTableRowActions = ({ actions, row, onPreview, onPrint }) => {
  const isEdit =
    row.original.status === "INREVISE" || row.original.status === "REJECTED";
  const isNeedRevize = row.original.status === "NEEDREVISE";

  const isArchiveable = row.original.status === "SUCCESS";

  if (!actions || actions.length === 0) {
    return null; // Eğer actions undefined veya boşsa, hiçbir şey render etme
  }

  return (
    <div className="w-full flex gap-2 justify-center items-center">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="text-muted-foreground "
              size="sm"
              onClick={() => onPreview(row.original)}
            >
              <View size={19} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-xs">Formu İncele</p>
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="text-muted-foreground "
              size="sm"
              onClick={() => onPrint(row.original)}
            >
              <Printer size={19} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-xs">Formu Yazdır</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            size="sm"
            // className={` data-[state=open]:bg-muted`}
            variant="outline"
            className="text-muted-foreground "
          >
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {actions.map((action, index) => {
            if (action.label === "Düzenle" && !isEdit) {
              return null;
            }
            if (action.label === "Revize Talep" && isEdit) {
              return null;
            }
            if (action.label === "Revize Talep" && isNeedRevize) {
              return null;
            }
            if (action.label === "Revize Talep" && isEdit) {
              return null;
            }
            if (action.label === "Revizeye Gönder" && !isNeedRevize) {
              return null;
            }

            if (action.label === "Formu Arşivle" && !isArchiveable) {
              return null;
            }

            /* formu anonim kişi doldurdu ise, actions menüsünde sadece formu arşivle ve sil butonunu göster. */
            if (
              row.original.filler_user_is_anon &&
              (action.label != "Formu Arşivle" && action.label != "Formu Sil") &&
              isArchiveable
            ) {
              return null;
            }

            return (
              <DropdownMenuItem
                key={index}
                onClick={() => action.action(row.original)}
              >
                {action.label}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default DataTableRowActions;
