import BreadCrumb from "@/components/BreadCrumb";
import {
  SwitchInput,
  TextArea,
  TextInput,
} from "@/components/formfields/Inputs";
import { Heading } from "@/components/Heading";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { apiconfig } from "@/lib/apiconfig";
import axios from "axios";
import { SaveAll } from "lucide-react";
import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CheckboxInput } from "../components/formfields/Inputs";
import { useAuth } from "@/context/authContext";

const breadcrumbItems = [
  { title: "Form Listesi", link: "/editable-forms" },
  { title: "Form Doldur", link: "/fill-form/" },
];

const FillForm = ({ isloggedin = false }) => {
  const { user } = useAuth();
  const [form, setForm] = useState({});
  const [responsibleUserID, setResponsibleUserID] = useState("");
  const [responsibleUsers, setResponsibleUsers] = useState([]);
  const { formQrCode } = useParams();
  const location = useLocation();

  const navigate = useNavigate();
  const { toast } = useToast();
  let apiUrl = "/api/fillform";

  useEffect(() => {
    const fetchResponsibleUsers = async (formdata) => {
      try {
        let responseResponsible;
        if (isloggedin) {
          console.log('formdata.work_group_id', formdata.work_group_id);
          responseResponsible = await axios.post(
            apiconfig().url + "/api/user/list/filter",
            {
              $and: [
                { role: { $in: ["MANAGER", "ADMIN"] } },
                {
                  $or: [
                    { work_group_id: formdata.work_group_id },
                    { other_work_group_ids: formdata.work_group_id }
                  ]
                }
              ]
            }
          );
          console.log(responseResponsible.data || responseResponsible);
          setResponsibleUsers(responseResponsible.data || responseResponsible);
        }
      } catch (error) {
        console.error("Veri çekme hatası users", error);
        navigate("/");
      }
    };

    const fetchData = async () => {
      try {
        console.log("Giriş yapmış kullanıcı:", user);
        if (isloggedin) {
          apiUrl = "/api/form";
        }

        let response;
        if (isloggedin) {
          response = await axios.get(
            apiconfig().url + apiUrl + "/get?formQrCode=" + formQrCode
          );
        } else {
          response = await fetch(
            apiconfig().url + apiUrl + "/get?formQrCode=" + formQrCode
          ).then((res) => res.json());
        }

        let isUserInWorkGroup = false
        if (user.role === "ADMIN" || user.role === "MANAGER") {
          isUserInWorkGroup = true
        } else if (response.data.work_group_id === user.work_group_id) {
          isUserInWorkGroup = true
        } else {
          // döngü ile diğer çalışma gruplarını kontrol et
          for (let i = 0; i < user.other_work_group_ids.length; i++) {
            if (user.other_work_group_ids[i] === response.data.work_group_id) {
              isUserInWorkGroup = true
              break
            }
          }
        }

        fetchResponsibleUsers(response.data)

        console.log(response.data || response)

        if (isUserInWorkGroup) {
          setForm(response.data || response)
        } else {
          navigate("/")
        }
      } catch (error) {
        console.error("Veri çekme hatası", error)
        navigate("/")
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (form.status === false) {
      navigate("/", { state: { from: location.pathname } });
    }
  }, [form.status]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const fillerUserId = localStorage.getItem("_id");

    let data = {
      filled_form_id: form._id,
      name: form.name,
      filled_fields: form.fields,
      status: responsibleUserID || null ? "TOAPPROVAL" : "SUCCESS",
      filler_user_id: fillerUserId,
      responsible_user_id: responsibleUserID || null,
    };
    console.log(responsibleUserID || null, form.settings.anon_people_can_fill);
    
    if (!form.settings.anon_people_can_fill && form.settings.responsible_user_required && !responsibleUserID) {
      toast({
        variant: "destructive",
        title: "Sorumlu seçiniz!",
        description: "Lütfen bir form sorumlusu seçin.",
        status: "error",
      });
      return;
    }
    console.log("Kaydedilecek form:", data);

    try {
      let response;
      if (isloggedin) {
        response = await axios.post(apiconfig().url + apiUrl + "/save", data);
      } else {
        data.filler_user_is_anon = true;
        response = await fetch(apiconfig().url + apiUrl + "/save", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then((res) => res.json());
      }
      console.log(response);

      const savedForm = response.data?.savedForm || response?.savedForm;
      console.log("Form kaydedildi:", response.data || response, savedForm);
      navigate('/completed-forms')
      toast({
        title: "Form Kaydedildi!",
        description:
          responsibleUserID || null
            ? "Formunuz, seçtiğiniz sorumlu kişisine değerlendirilmesi üzere gönderildi."
            : "Formunuz başarılı şekilde sisteme kaydedildi.",
      })
    } catch (error) {
      console.error("Form kaydedilemedi:", error);
      toast({
        variant: "destructive",
        title: "Formunuz kaydedilirken bir hata aldık!",
        description: error.response
          ? error.response.data.message
          : error.message,
      });
    }
  };

  const handleChangeField = (id, event) => {
    const newFields = form.fields.map((field) => {
      console.log(field);
      if (field.id === id) {
        return { ...field, [event.target.name]: event.target.value };
      }
      return field;
    });
    setForm({ ...form, fields: newFields });
  };

  return (
    <ScrollArea className="h-full w-full">
      <div className="flex-1 space-y-4 p-4 pt-6 md:p-8">
        <BreadCrumb items={breadcrumbItems} />
        <div className="flex items-center justify-between space-y-2">
          <Heading
            title={"Form Doldur"}
            description={` `}
          />
        </div>

        <Card>
          <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-3">
            <div>
              <div className="grid grid-cols-8 border-b">
                <div className="border-e p-2">
                  <img
                    width="75"
                    className="m-auto"
                    src="https://upload.wikimedia.org/wikipedia/tr/a/a5/Recep_Tayyip_Erdo%C4%9Fan_%C3%9Cniversitesi_logo.jpg"
                  />
                </div>
                <div className="grid grid-cols-1 col-span-7 xs:col-span-3 lg:col-span-5 text-center">
                  <div className="text-sm xs:text-md lg:text-lg xl:text-xl border-b font-semibold pt-1">
                    DİŞ HEKİMLİĞİ FAKÜLTESİ
                  </div>
                  <div className="text-sm xs:text-md md:text-xl xl:text-3xl p-2 lg:p-4">
                    {form.name}
                  </div>
                </div>
                {form.document_data && (
                  <div className="grid grid-cols-2 col-span-8 text-xs sm:text-base xs:col-span-4 lg:col-span-2 border-s-0 xs:border-s border-t xs:border-t-0">
                    <div className="border-e p-1 border-b text-slate-700">
                      Döküman Kodu
                    </div>
                    <div className="p-1 border-b text-slate-700">
                      {form.document_data["document_code"]}
                    </div>
                    <div className="border-e p-1 border-b text-slate-700">
                      Revizyon No
                    </div>
                    <div className="p-1 border-b text-slate-700">
                      {form.document_data["document_revision_number"]}
                    </div>
                    <div className="border-e p-1 border-b text-slate-700">
                      Yayın Tarihi
                    </div>
                    <div className="p-1 border-b text-slate-700">
                      {form.document_data["document_release_date"]}
                    </div>
                    <div className="border-e p-1 border-b text-slate-700">
                      Revizyon Tarihi
                    </div>
                    <div className="p-1 border-b text-slate-700">
                      {form.document_data["document_revision_date"]}
                    </div>
                    <div className="border-e p-1 text-slate-700">
                      Sayfa No
                    </div>
                    <div className="p-1 text-slate-700">
                      {form.document_data["document_page_number"]}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <CardContent className="grid grid-cols-7 py-2">
              <div></div>
              <div className="col-span-7 xl:col-span-5 space-y-3">
                {form?.fields?.map((field) => {
                  switch (field.type) {
                    case "form_description_textbox":
                      return (
                        <TextArea
                          key={field.id}
                          field={field}
                          readonly={true}
                        />
                      );
                    case "text":
                    case "number":
                      return (
                        <TextInput
                          key={field.id}
                          field={field}
                          handleChange={handleChangeField}
                        />
                      );
                    case "textbox":
                      return (
                        <TextArea
                          key={field.id}
                          field={field}
                          handleChange={handleChangeField}
                        />
                      );
                    case "switch":
                      return (
                        <SwitchInput
                          key={field.id}
                          field={field}
                          handleChange={handleChangeField}
                        />
                      );
                    case "checkbox":
                      return (
                        <CheckboxInput
                          key={field.id}
                          field={field}
                          handleChange={handleChangeField}
                        />
                      );
                    default:
                      return null;
                  }
                })}
              </div>
              <div></div>
            </CardContent>
            <CardFooter className="grid grid-cols-1 lg:grid-cols-2 space-y-2 pt-0 place-content-center place-items-center">
              {!form?.settings?.anon_people_can_fill &&
                form?.settings?.responsible_user_required && (
                  <div className="flex  items-center space-x-2">
                    <div className="flex-1">Form sorumlusu:</div>
                    <Select
                      onValueChange={(value) => setResponsibleUserID(value)}
                      value={responsibleUserID}
                      required={true}
                      className=""
                    >
                      <SelectTrigger className="w-[200px]">
                        <SelectValue placeholder="Form sorumlusu seçin.." />
                      </SelectTrigger>
                      <SelectContent>
                        {responsibleUsers.map((user) => (
                          <SelectItem key={user._id} value={user._id}>
                            {console.log(user)}
                            {user.firstname + " " + user.lastname}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                )}
              <Button className="">
                <SaveAll className="mr-1.5" size={20} />
                Formu Kaydet
              </Button>
            </CardFooter>
          </form>
        </Card>
      </div>
    </ScrollArea>
  );
};

export default FillForm;
