import DataTable from "@/components/editable-forms-data-table/data-table";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/context/authContext";
import { formDeleteRequest, getAllForms } from "@/lib/editableForms.service";
import { queryKeys } from "@/lib/queryKey.factory";
import { roles } from "@/lib/roleEnum";
import { getUser } from "@/lib/user.service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PlusCircle } from "lucide-react";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import { Heading } from "../../components/Heading";
import { Button } from "../../components/ui/button";
import { getAllFormsColumns } from "./editable-forms-columns";

const breadcrumbItems = [
  { title: "Form Listesi", link: "/dashboard/editable-forms" },
];

export default function AllForms() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useAuth();
  console.log(user);
  if (user.role === roles.DEFAULTUSER || user.role === roles.MANAGER) {
    navigate("/completed-forms");
  }

  const queryClient = useQueryClient();

  const { data: allForms, isFetching } = useQuery({
    queryKey: queryKeys.fetchCompletedForms.all,
    queryFn: () => getAllForms(),
    initialData: [],
  });

  const { data: userData, isFetching: isFetchingUser } = useQuery({
    queryKey: queryKeys.fetchUser.single,
    queryFn: () => getUser(),
    initialData: [],
  });

  //form edit
  const onEdit = useCallback(async (form) => {
    navigate(`/edit-form/${form._id}`);
  }, []);

  //form doldurma görüntüleme
  const onFillForm = useCallback(async (form) => {
    navigate(`/fill-form/${form.qr_code}`);
  }, []);

  const onDelete = useMutation({
    mutationFn: formDeleteRequest,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.fetchCompletedForms.all);
      toast({
        title: "Başarılı",
        description: "Form başarıyla silindi.",
      });
    },
    onError: () => {
      toast({
        title: "Hata!",
        description: "Form silinirken bir hata oluştu.",
        variant: "destructive",
      });
    },
  });

  const onPrintQr = useCallback(() => {
    console.log("print");
  }, []);

  const columns = useMemo(
    () =>
      getAllFormsColumns({
        user,
        onPrintQr,
        onEdit,
        onFillForm,
        onDelete: onDelete.mutate,
      }),
    []
  );

  return (
    <ScrollArea className="h-full">
      <div className="flex-1 space-y-4 p-4 pt-6 md:p-8">
        <BreadCrumb items={breadcrumbItems} />
        <div className="flex items-center justify-between space-y-2">
          <Heading
            title={"Form Listesi"}
            description={
              "Personel tarafından oluşturulan formların yönetim ekranı"
            }
          />
          <div className="flex justify-center items-center">
            <Button variant="outline" asChild>
              <Link
                to="/create-form"
                className="flex justify-center items-center gap-2 font-medium "
              >
                <PlusCircle size={19} />
                Yeni Form
              </Link>
            </Button>
          </div>
        </div>
        <Separator />

        <div className="grid grid-cols-1  rounded-lg">
          {isFetching && <span className="px-5 py-5 ">Yükleniyor..</span>}
          {!isFetching && (
            <DataTable data={allForms} columns={columns} user={userData} />
          )}
        </div>
      </div>
    </ScrollArea>
  );
}
