import {
  DraggableInput,
  DroppableForm,
  inputTypes,
} from "@/components/DraggableFields";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import axios from "axios";
import { Cog, Component, FilePen, SaveAll, ScanLine } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate, useParams } from "react-router-dom";
import GenerateQrCode from "./GenerateQrCode";

import BreadCrumb from "@/components/BreadCrumb";
import { Heading } from "@/components/Heading";
import NavigatorMenu from "@/components/NavigatorMenu";
import { Separator } from "@/components/ui/separator";
import { useToast } from "@/components/ui/use-toast";
import { apiconfig } from "@/lib/apiconfig";
import {
  DocumentFields,
  GetDocumentFieldValues,
} from "../components/DocumentFields";
import { FormSettings } from "../components/FormSettings";
import WorkGroupField from "../components/WorkGroupField";

const EditForm = () => {
  const navigate = useNavigate();
  const { formId } = useParams();
  const formSettingsRef = useRef();
  const [form, setForm] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [formDocumentData, setFormDocumentData] = useState([]);
  const { toast } = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          apiconfig().url + "/api/form/get?formId=" + formId
        );
        const formData = response.data;
        console.log(formData);
        setForm(formData);
        setFormFields(formData.fields);
        setFormDocumentData(formData.document_data);
      } catch (error) {
        console.error("Veri çekme hatası", error);
      }
    };

    fetchData();
  }, [formId]);

  const handleDrop = useCallback((item) => {
    setFormFields((prevFields) => {
      const newId = prevFields.length + 1;
      return [...prevFields, { ...item, id: newId }];
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const target = event.target;

    const cleanFields = formFields.map(({ icon, ...field }) => ({
      ...field,
    }));

    const documentData = GetDocumentFieldValues(target);
    const formSettingsData = formSettingsRef.current.getSettingsValues();

    const data = {
      _id: formId,
      name: target.form_title.value,
      work_group_id: target.work_group_id.value,
      fields: cleanFields,
      document_data: documentData,
      settings: formSettingsData,
    };

    console.log("Güncellenecek form:", data);

    try {
      const response = await axios.post(
        apiconfig().url + "/api/form/update",
        data
      );
      const updatedForm = response.data.updatedForm;
      console.log("Form güncellendi:", response.data, updatedForm);
      toast({
        title: "Form güncellendi!",
        description: "Formunuz başarılı şekilde güncellendi.",
      });
    } catch (error) {
      console.error("Form güncellenemedi:", error);
      toast({
        variant: "destructive",
        title: "Formunuz güncellenirken bir hata aldık!",
        description: error.response.data.message,
      });
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex-1 space-y-4 p-4 pt-6 md:p-8">
        <BreadCrumb
          items={[{ title: "Form Güncelleme", link: "/editable-forms" }]}
        />
        <div className="flex items-center justify-between space-y-2">
          <Heading
            title={"Form güncelle"}
            description={
              "Oluşturulan formun satırlarını silebilir veya düzenleyebilirsiniz."
            }
          />
          <NavigatorMenu show={true} />
        </div>
        <Separator />
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-4 gap-4">
            <Card className="col-span-4 xl:col-span-1 w-full xl:row-span-2 h-fit sticky top-2">
              <CardHeader>
                <CardTitle className="flex items-center space-x-2">
                  <Component size={21} strokeWidth={2} />
                  <div>Form komponentleri</div>
                </CardTitle>
                <CardDescription>
                  Form komponentlerini sürükleyerek, formun satırlarını
                  oluşturabilirsiniz.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid space-y-1.5">
                {inputTypes.map((element, index) => (
                  <DraggableInput key={index} {...element} />
                ))}
              </CardContent>
            </Card>

            <Card className="col-span-4 xl:col-span-3">
              <CardHeader>
                <CardTitle className="flex items-center space-x-2">
                  <FilePen size={21} strokeWidth={2} />
                  <div>Form güncelleme</div>
                </CardTitle>
                <CardDescription>
                  Form komponentlerini kullanarak sürükle-bırak ile formunuzu
                  değiştirebilirsiniz.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid space-y-2">
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-3 border-b pb-4">
                  <div className="grid space-y-1">
                    <Label htmlFor="form_title">Form adı</Label>
                    <Input
                      type="text"
                      name="form_title"
                      id="form_title"
                      defaultValue={form.name}
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="grid space-y-1">
                    <Label>Çalışma grubu</Label>
                    {form.work_group_id && (
                      <WorkGroupField
                        defaultValue={form.work_group_id}
                        name="work_group_id"
                      />
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-5 gap-3 border-b pt-1 pb-4">
                  <DocumentFields filldata={formDocumentData} />
                </div>
                <div className="relative flex py-3 items-center">
                  <div className="flex-grow border-t border-dashed border-gray-200"></div>
                  <span className="flex-shrink mx-4 text-gray-400 tracking-widest">
                    FORM DIZAYNI
                  </span>
                  <div className="flex-grow border-t border-dashed border-gray-200"></div>
                </div>
                <div>
                  <DroppableForm
                    onDrop={handleDrop}
                    formFields={formFields}
                    setFormFields={setFormFields}
                  />
                </div>
              </CardContent>
            </Card>

            <div className="col-span-4 xl:col-span-3 grid grid-cols-1 lg:grid-cols-2 gap-4">
              <Card>
                <GenerateQrCode formQrCode={form.qr_code} name={form.name}>
                  <CardHeader>
                    <CardTitle className="flex items-center space-x-2">
                      <ScanLine size={21} strokeWidth={2} />
                      <div>Form barkodu</div>
                    </CardTitle>
                    <CardDescription>
                      Form barkodunu yazdırıp, barkodu kameranıza okutarak formu
                      doldurabilirsiniz.
                    </CardDescription>
                  </CardHeader>
                </GenerateQrCode>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center space-x-2">
                    <Cog size={21} strokeWidth={2} />
                    <div>Form ayarları</div>
                  </CardTitle>
                  <CardDescription>
                    Form ayarlarında değişiklik yapabilirsiniz.
                  </CardDescription>
                </CardHeader>
                <CardContent className="grid grid-cols-1 gap-5 text-slate-700">
                  <FormSettings
                    ref={formSettingsRef}
                    filldata={form.settings}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="w-full flex justify-end mt-4">
            <Button className="w-fit space-x-2">
              <SaveAll className="mr-1.5" size={20} />
              Formu Güncelle
            </Button>
          </div>
        </form>
      </div>
    </DndProvider>
  );
};

export default EditForm;
