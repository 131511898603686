import { Toaster } from "@/components/ui/toaster";
import { useToast } from "@/components/ui/use-toast";
import api from "@/lib/api.service";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const { toast } = useToast();

  const [token, setToken] = useState(localStorage.getItem("token" | ""));
  const navigate = useNavigate();

  const location = useLocation();
  console.log(location);

  const getPreviousPath = (location) => {
    if (location.state?.from?.state?.from) {
      return location.state.from.state.from;
    }
    return location.state?.from || "/";
  };

  const loginAction = async (username, password) => {
    try {
      const response = await api.post("/user/login", {
        username: username,
        password: password,
      });
      if (response.data.status) {
        console.log(response.data);
        const { token, user } = response.data;

        const userId = user._id;
        localStorage.setItem("token", token);
        localStorage.setItem("_id", userId);

        localStorage.setItem("user", JSON.stringify(user));
        const fromPath = getPreviousPath(location);

        // Önceki yolu alın

        console.log("önceki yol:", fromPath);
        setUser(user);
        navigate(fromPath, { replace: true });
        // Önceki yolu alın
        return;
      }
      throw new Error("Hata oluştu...");
    } catch (error) {
      console.error(error);
      toast({
        variant: "destructive",
        title: "Giriş yaparken bir hata aldık!",
        description: error?.response?.data?.message,
      });
    }
  };
  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("_id");
    localStorage.removeItem("username");
    localStorage.removeItem("user");

    navigate("/logout");
  };
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ token, user, setUser, loginAction, logOut }}>
      {children}
      <Toaster />
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
