import api from "@/lib/api.service";

const URL = "/user";

export const getAllUser = async () => {
  const { data } = await api.get(`${URL}/list`);
  return data;
};

export const getUser = async () => {
  const userId = localStorage.getItem("_id");
  const { data } = await api.get(`${URL}/get?userId=${userId}`);
  console.log("user:", data);
  return data;
};

// export const createUser = async (user) => {
//   const { data } = await api.post(URL, user);
//   return data;
// };

// export const updateUser = async (id, user) => {
//   const { data } = await api.put(`${URL}/update/?id=${id}`, user);
//   return data;
// };

// export const deleteUser = async (id) => {
//   return api.delete(`${URL}/${id}`);
// };
