import {
  ClipboardCheck,
  Component,
  FileArchive,
  FilePen,
  FilePlus,
  LogOut,
  MonitorPause,
  Settings,
  UserRoundCog,
} from "lucide-react";

export const DASHBOARD_SIDEBAR_LINKS = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/",
    icon: <MonitorPause size={19} />,
    divide: true,
  },
  {
    key: "create-form",
    label: "Form Oluştur",
    path: "/create-form",
    icon: <FilePlus size={19} />,
  },
  {
    key: "editable-forms",
    label: "Oluşturulan Formlar",
    path: "/editable-forms",
    icon: <FilePen size={19} />,
    divide: true,
  },
  {
    key: "completed-forms",
    label: "Doldurulan Formlar",
    path: "/completed-forms",
    icon: <ClipboardCheck size={19} />,
    divide: true,
  },

  {
    key: "archived-forms",
    label: "Arşivlenen Formlar",
    path: "/archived-forms",
    icon: <FileArchive size={19} />,
    divide: true,
  },

  {
    key: "working-groups",
    label: "Çalışma Grupları",
    path: "/working-groups",
    icon: <Component size={19} />,
  },
  {
    key: "user-manager",
    label: "Kullanıcı İşlemleri",
    path: "/admin/user-manager",
    icon: <UserRoundCog size={19} />,
  },
  {
    key: "settings",
    label: "Ayarlar",
    path: "/settings",
    icon: <Settings size={19} />,
  },
];
export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
  {
    key: "logout",
    label: "Çıkış Yap",
    path: "/logout",
    icon: <LogOut />,
  },
];
