import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/context/authContext";
import { Eye, EyeOff, LogIn } from "lucide-react";

const Login = () => {
  const auth = useAuth();
  const { toast } = useToast();

  const navigate = useNavigate();
  const location = useLocation();
  console.log("layout location:", location);

  const [showPassword, setShowPassword] = useState(false);

  // clear storage on load
  useEffect(() => {
    // remove token from storage
    localStorage.removeItem("token");
    localStorage.removeItem("_id");
    localStorage.removeItem("username");
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const username = form.username.value.toLowerCase();
    const password = form.password.value.toLowerCase();
    console.log(username, password);

    try {
      await auth.loginAction(username, password);
      return;
    } catch (error) {
      console.error("Login failed:", error);
      toast({
        variant: "destructive",
        title: "Giriş yaparken bir hata aldık!",
        description: error?.response?.data?.message,
      });
    }
  };

  return (
    <div className="grid w-full grid-cols-1 xl:grid-cols-6 bg-white">
      <div className="h-screen col-span-4 hidden xl:flex rounded-e-2xl items-center bg-gradient-to-br from-blue-900 via-gray-800 to-gray-900 justify-center">
        <div className="m-auto text-white pointer-events-none absolute space-y-1">
          <div className="text-5xl">BiGateWare Forms®</div>
          <div className="text-sm italic">
            BiGateWare Forms yazılımı, form işlemlerinizi kolaylaştırma amacıyla
            geliştirilmiş inovatif bir çözümdür.
          </div>
        </div>
      </div>
      <div className="bg-white col-span-2 text-gray-800 h-screen xl:h-auto flex">
        <Card className="m-auto mt-0 md:mt-5 xl:mt-auto shadow-none border-none">
          <form onSubmit={handleSubmit}>
            <CardHeader className="p-0">
              <div className="m-auto mb-4 block xl:hidden pointer-events-none bg-slate-900 text-white w-full p-6 rounded-none sm:rounded-3xl">
                <div className="text-3xl font-bold md:text-5xl">
                  BiGateWare Forms®
                </div>
                <div className="text-sm italic">
                  BiGateWare Forms yazılımı, form işlemlerinizi kolaylaştırma
                  amacıyla geliştirilmiş inovatif bir çözümdür.
                </div>
              </div>
              <CardTitle className="text-3xl px-6 font-bold">
                Kullanıcı girişi
              </CardTitle>
              <CardDescription className="px-6">
                Devam edebilmek için lütfen kullanıcı hesabınıza giriş yapın.
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-2 mt-4 mb-2 text-gray-800">
              <div>
                <Label className="text-sm">Kullanıcı Adı</Label>
                <Input
                  placeholder="Kullanıcı adınızı giriniz"
                  className="!lowercase"
                  id="username"
                  required
                />
              </div>
              <div>
                <Label className="text-sm">Şifre</Label>
                <div className="flex space-x-1">
                  <Input
                    placeholder="Şifrenizi giriniz"
                    id="password"
                    className="!lowercase"
                    type={showPassword ? "text" : "password"}
                    required
                  />
                  <span
                    className="border rounded-md cursor-pointer flex px-2"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <Eye className="w-6 h-6 m-auto" />
                    ) : (
                      <EyeOff className="w-6 h-6 m-auto" />
                    )}
                  </span>
                </div>
              </div>
            </CardContent>
            <CardFooter>
              <Button
                type="submit"
                className="bg-gray-800 transition-all hover:text-green-200 hover:bg-slate-900 w-full space-x-1"
              >
                <span>Giriş Yap</span>
                <LogIn size={19} strokeWidth={2} />
              </Button>
            </CardFooter>
          </form>
        </Card>
      </div>
    </div>
  );
  {
    /* <div className="container mx-auto bg-gray-200 rounded-xl p-8 m-10">
      <p className="text-3xl text-gray-700 font-bold mb-5">Kullanıcı Girişi</p>
      <p className="text-gray-500 text-lg">Bu, kullanıcı girişi sayfasıdır.</p>

      <form onSubmit={handleSubmit} className='mt-2 space-x-2'>
        <input type="text" id="username" className="p-2 rounded-lg bg-white border-2 border-blue-500" placeholder="Kullanıcı adı" />
        <input type="text" id="password" className="p-2 rounded-lg bg-white border-2 border-blue-500" placeholder="Şifre" />

        <button className='p-2 px-3 rounded-lg bg-blue-500 text-white'>Giriş</button>
      </form>
    </div> */
  }
};

export default Login;
